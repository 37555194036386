import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import { isMaplibre } from "../utils/isMaplibre";
import type { AVLType } from "./utils";

interface UseAVLPositionsMapLayersParams {
  avlType: AVLType;
  layerId: string;
  opacity?: number;
  showLabels?: boolean;
  sourceId: string;
}

const useAVLPositionsMapLayers = ({
  avlType,
  layerId,
  opacity = 1,
  showLabels = true,
  sourceId,
}: UseAVLPositionsMapLayersParams) => {
  const map = useMapContext();
  const theme = useTheme();
  const textLayerId = `${layerId}-text`;

  useEffect(() => {
    map.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: layerId,
        type: "symbol",
        source: sourceId,
        layout: {
          "icon-image": [
            "coalesce",
            ["image", ["get", "StatusCategory"]],
            ["image", `${avlType}-fallback`],
          ],
          "icon-allow-overlap": true,
          "icon-rotate": ["get", "DirectionHor"],
          "icon-rotation-alignment": "map",
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    map.addLayer(
      {
        id: textLayerId,
        type: "symbol",
        source: sourceId,
        minzoom: 8,
        layout: {
          "text-field": ["get", "CallSign"],
          "text-font": isMaplibre(map) ? ["Noto Sans Bold"] : ["DIN Pro Bold"],
          "text-offset": [1.5, 0],
          "text-max-width": 100,
          "text-anchor": "left",
          "text-size": 12,
        },
        paint: {
          "text-color": theme.colors.neutrals.text,
          "text-halo-color": "#FFF",
          "text-halo-width": 1,
          "text-halo-blur": 0,
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(textLayerId)) {
        map.removeLayer(textLayerId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, [
    avlType,
    layerId,
    map,
    sourceId,
    textLayerId,
    theme.colors.neutrals.text,
  ]);

  useEffect(() => {
    map.setLayoutProperty(
      textLayerId,
      "visibility",
      showLabels ? "visible" : "none",
    );
  }, [map, showLabels, textLayerId]);

  useEffect(() => {
    map.setPaintProperty(layerId, "icon-opacity", opacity);
    map.setPaintProperty(textLayerId, "text-opacity", opacity);
  }, [layerId, map, opacity, textLayerId]);
};

export default useAVLPositionsMapLayers;
